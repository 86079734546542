import React, { Fragment } from 'react';
import { Box } from '@rebass/emotion';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Wrap from '../components/Wrap';
import Text from '../components/Text';
import TextCaps from '../components/TextCaps';
import Heading from '../components/Heading';

const WhoWeAre = () => (
  <Fragment>
    <Wrap flexWrap="wrap">
      <Box data-aos="fade-up" width={[1, 1/10, 1/10]}>
        <TextCaps>Who We Are</TextCaps>
      </Box>
      <Box width={[1, 9/10]}>
        <Box data-aos="fade-up" width={1} mb={[4, 4, 6]}>
          <StaticQuery
            query={graphql`
              query {
                placeholderImage: file(relativePath: { eq: "team.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
          />
        </Box>
        <Box width={[1, 8/10, 6/10]}>
          <Heading data-aos="fade-up" color="darkGray" as="h2" fontSize={[ 5, 6, 6 ]} lineHeight={1} mb={4}>We’re a team of developers and designers that love solving the most difficult problems.</Heading>
          <Text data-aos="fade-up" data-aos-delay="100" fontSize={[1,2,3]}>We have 20+ combined years of experience working with big brands like GE, Suzuki and AT&T. We founded UrbanStems, an on-demand flower delivery startup, and it quickly became one of the most successful floral startups in the country. We’ve led numerous strategy sessions, onboarded new employees, and led design and engineering teams.</Text>
        </Box>
      </Box>
    </Wrap>
  </Fragment>
);

export default WhoWeAre;
