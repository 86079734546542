/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import WavesLarge from '../images/waveslg.inline.svg';
import WavesSmall from '../images/wavessm.inline.svg';

const Slice = ({ theme, large, color, bg, rotation, ...props }) => {

  const size = large ? '20' : '8';
  const Shape = styled('div')`
    background-color: ${bg};
    display: inline-block;
    height: ${size*2}vw;
    width: ${(size*2) / 2}vw;
    border-radius: ${size*2}vw 0 0 ${size*2}vw;
    transform: rotate(${rotation}deg);
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      height: ${size*1.5}vw;
      width: ${(size*1.5) / 2}vw;
      border-radius: ${size*1.5}vw 0 0 ${size*1.5}vw;
    }
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: ${size}vw;
      width: ${size / 2}vw;
      border-radius: ${size}vw 0 0 ${size}vw;
    }
  `;

  const WavesLg = styled(WavesLarge)`
    fill: ${color};
  `;

  const WavesSm = styled(WavesSmall)`
    fill: ${color};
  `;

  let Wave = '';

  if (color && large) {
    Wave = WavesLg;
  } else if (color) {
    Wave = WavesSm;
  }

  return (
    <Shape
      {...props}
    >
      {color &&
        <Wave />
      }
    </Shape>
  );
};

Slice.defaultProps = {
  large: false,
  color: '',
  rotation: 0,
  bg: '#202332',
}

Slice.propTypes = {
  large: PropTypes.bool,
  color: PropTypes.string,
  bg: PropTypes.string,
  rotation: PropTypes.number,
};

export default Slice;
