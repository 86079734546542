import React from 'react';
import { Box, Flex } from '@rebass/emotion';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading from '../components/Heading';
import Text from '../components/Text';
import TextCaps from '../components/TextCaps';
import Wrap from '../components/Wrap';

import WhatWeDo from '../modules/WhatWeDo';
import WhoWeAre from '../modules/WhoWeAre';
import ProjectCards from '../modules/ProjectCards';
import Slices from '../modules/Slices';

const HeadingContainer = styled(Box)`
  position: relative;
  pointer-events: none;
  z-index: 2;
`;

const HeadingBox = styled(Flex)`
  border-top: 1px solid rgba(255,255,255,0.2);
  position: relative;
  z-index: 2;
`;

const GreenLight = styled('span')`
  border-radius: 50%;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #7ADFC9;
`;

const DesktopOnly = styled(Box)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: block;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Citra. A digital product and UX studio." keywords={[`ux`, `design`, `product`, `studio`, `agency`, `dc`, `washington`]} />
    <Wrap flexWrap="wrap">
      <HeadingContainer ml={[0, '10%']} py={['26%', '12%', '15%']} width={[ 1, 8/10, 8/10 ]}>
          <Heading as="h1" data-aos="fade-up" color="white" lineHeight={0} mt={3} fontSize={[ 6, 7, 8 ]}>We design &amp; build products that people love.</Heading>
      </HeadingContainer>
      <HeadingBox data-aos="fade-up" data-aos-delay="100" data-aos-offset="0" flexWrap="wrap" ml={[0, '10%']} py={4} width={[10/10]}>
        <Box width={[5/10, 3/10, 2/10]}>
          <Text fontSize={['14px', 0]} color="white">Headquarted In</Text>
          <Text fontSize={[1, 2]} color="white">Washington, DC</Text>
        </Box>
        <Box width={[5/10, 3/10, 1.5/10]}>
          <Text fontSize={['14px', 0]} color="White">Founded In</Text>
          <Text fontSize={[1, 2]} color="white">2019</Text>
        </Box>
        <DesktopOnly width={[0, 2/10, 2/10]}>
          <Text fontSize={0} color="white">Current Status</Text>
          <Text fontSize={2} color="white"><GreenLight />Accepting Clients</Text>
        </DesktopOnly>
      </HeadingBox>
      <Slices />
    </Wrap>
    <Box bg="white" style={{ position: 'relative' }} py={['16%', '10%']}>
      <WhatWeDo />
    </Box>
    <Box bg="lightBeige" style={{ position: 'relative' }} py={['16%', '10%']}>
      <Wrap flexWrap="wrap">
          <Box width={[1, 1/10]} data-aos="fade-up">
            <TextCaps>Our Work</TextCaps>
          </Box>
          <Box width={[1, 9/10]}>
            <ProjectCards />
          </Box>
        </Wrap>
      </Box>
      <Box bg="white" flexWrap="wrap" py={['16%', '8%']}>
        <WhoWeAre />
      </Box>
  </Layout>
);

export default IndexPage;
