/** @jsx jsx */
import { Fragment } from 'react';
import { Box, Flex } from '@rebass/emotion';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Wrap from '../components/Wrap';
import Text from '../components/Text';
import TextCaps from '../components/TextCaps';
import Heading from '../components/Heading';

import What1 from '../images/whaticon1.inline.svg';
import What2 from '../images/whaticon2.inline.svg';

const WhatIcon = styled(Box)`
  margin-right: 40px;
  max-width: 200px;
  svg {
    max-width: 100%;
  }
`;

const tabStyle = css`
  .react-tabs__tab-list {
    list-style: none;
    padding: 0;
  }
  .react-tabs__tab {
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    outline: none;
    padding: 8px 0px;
    margin-right: 30px;
    :hover {
      color: #282B3A;
    }
  }
  .selected {
    border-bottom: 3px solid #FDC800;
    color: #282B3A;
  }
`;



const WhatWeDo = () => (
  <Fragment>
    <Wrap flexWrap="wrap">
      <Box data-aos="fade-up" data-aos-offset="25" width={[1, 1/10, 1/10]}>
        <TextCaps>What We Do</TextCaps>
      </Box>
      <Box width={[1, 8/10, 6/10]}>
        <Heading data-aos="fade-up" data-aos-offset="25" color="darkGray" as="h2" fontSize={[ 5, 6, 6 ]} lineHeight={1} mb={4}>We’re a digital product studio that takes ideas from start to finish, sketch to launch, problem to solution.</Heading>
        <Text data-aos="fade-up" data-aos-offset="25" data-aos-delay="100" fontSize={[1,2,3]}>We build apps, websites and tools that are human-centered and put users first. We’re one of the few teams out there that has all the skills to strategize, design, build and launch a product in house.</Text>
      </Box>
    </Wrap>
    <Wrap>
      <Box css={tabStyle} ml={[0, '10%', '10%']} mt={['8%']}>
        <Tabs>
          <TabList data-aos="fade-up">
            <Tab selectedClassName="selected">New Products</Tab>
            <Tab selectedClassName="selected">Existing Products</Tab>
          </TabList>
          <TabPanel>
            <Flex data-aos="fade-up" flexDirection={['column', 'row']} flexWrap="wrap" alignItems={['flex-start', 'center']}>
              <WhatIcon width={['50%', '25%', '25%']} mr={'40px'} mb={[2, 0]}>
                <What1 />
              </WhatIcon>
              <Box flex="1" mr={[0, '10%', '25%']}>
                <Heading color="darkGray" as="h3" fontSize={[ 3, 3, 4 ]} lineHeight={1} mb={2}>Build a New Product</Heading>
                <Text fontSize={[1,2,2]}>We’ll collaborate closely with your team to launch your idea or startup. We help you find the right opportunity and put a beautiful product into your customers hands.</Text>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex data-aos="fade-up" flexDirection={['column', 'row']} flexWrap="wrap" alignItems={['flex-start', 'center']}>
              <WhatIcon width={['50%', '20%', '20%']} mr={'40px'} mb={[2, 0]}>
                <What2 />
              </WhatIcon>
              <Box flex="1" mr={[0, '10%', '15%']}>
                <Heading color="darkGray" as="h3" fontSize={[ 3, 3, 4 ]} lineHeight={1} mb={2}>Improve an Existing Product</Heading>
                <Text fontSize={[1,2,1]}>We believe iteration is the best way to imrove a product. We can help you itereate on a new feature, or improve your products UX.</Text>
              </Box>
            </Flex>
          </TabPanel>
        </Tabs>
      </Box>
    </Wrap>
  </Fragment>
);

export default WhatWeDo;
