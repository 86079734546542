/** @jsx jsx */
import { Flex, Box } from '@rebass/emotion';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';

import Slice from '../components/Slice';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 15 + 100 }px,${y / 500 + 100 }px,0)`
const trans2 = (x, y) => `translate3d(${x / 8}px,${y / 15 - 25}px,0)`
const trans3 = (x, y) => `translate3d(${x / 20 }px,${y /10 + 75}px,0)`
const trans4 = (x, y) => `translate3d(${x / 10 + 150}px,${y / 15 + 50}px,0)`
const trans5 = (x, y) => `translate3d(${x / 20 - 100}px,${y / 5}px,0)`
const trans6 = (x, y) => `translate3d(${x / 250 + 100}px,${y / 20 + 50}px,0)`
const trans7 = (x, y) => `translate3d(${x / 20 }px,${y /10}px,0)`
const trans8 = (x, y) => `translate3d(${x / 7 - 40 }px,${y / 500 + 20 }px,0)`
const trans9 = (x, y) => `translate3d(${x / 5 }px,${y / 20 + 10 }px,0)`
const trans10 = (x, y) => `translate3d(${x / 20 }px,${y / 30 + 100}px,0)`

const SliceContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  @media (max-width: 40em) {
    .smallSlice {
      display: block;
    }
    .mediumSlice, .largeSlice {
      display: none;
    }
  }
  @media(min-width: 40em) {
    .smallSlice {
      display: none;
    }
    .largeSlice {
      display: none;
    }
    .mediumSlice {
      display: block;
    }
    @media(min-width: 72em) {
      .smallSlice {
        display: none;
      }
      .largeSlice {
        display: block;
      }
      .mediumSlice {
        display: none;
      }
  }
`;

const SliceRow = styled(Flex)`
  width: 100%;
`;

const Slices = () => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 1, tension: 100, friction: 20 } }));
  return(
    <SliceContainer data-aos="fade-up" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <div className="smallSlice">
          <SliceRow flexWrap="wrap">
            <Box width={6/12}><animated.div style={{ transform: props.xy.interpolate(trans8) }}><Slice large color="#8E43FF" rotation={-45} /></animated.div></Box>
            <Box width={6/12}><animated.div style={{ transform: props.xy.interpolate(trans9), textAlign: 'center' }}><Slice large rotation={45} color="#FDC800" /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap">
            <Box width={6/12}><animated.div style={{ transform: props.xy.interpolate(trans10) }}><Slice large color="#29A0FB" rotation={135} /></animated.div></Box>
            <Box width={6/12}><animated.div style={{ transform: props.xy.interpolate(trans3), textAlign: 'center' }}><Slice rotation={-135} color="#7ADFC9" /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap" justifyContent="flex-end">
            <Box width={6/12}><animated.div style={{ transform: props.xy.interpolate(trans3), textAlign: 'center' }}><Slice rotation={-45} color="#FDC800" /></animated.div></Box>
          </SliceRow>
        </div>
        <div className="mediumSlice">
          <SliceRow flexWrap="wrap">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans1) }}><Slice color="#8E43FF" rotation={135} /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans2) }}><Slice large rotation={-45} color="#7ADFC9" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans3), textAlign: 'center' }}><Slice rotation={-135} color="#FDC800" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans4), textAlign: 'center' }}><Slice large rotation={135} color="#FDC800" /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap" justifyContent="flex-end">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans7) }}><Slice color="#8E43FF" rotation={45} /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans5), textAlign: 'center' }}><Slice large rotation={-135} color="#FDC800" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans6), textAlign: 'center' }}><Slice rotation={-135} color="#7ADFC9" /></animated.div></Box>
            <Box flex="1" />
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans5), textAlign: 'center' }}><Slice large rotation={135} color="#29A0FB" /></animated.div></Box>
          </SliceRow>
        </div>
        <div className="largeSlice">
          <SliceRow flexWrap="wrap">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans1) }}><Slice color="#8E43FF" rotation={135} /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans2) }}><Slice large rotation={-45} color="#7ADFC9" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans3), textAlign: 'center' }}><Slice rotation={-135} color="#29A0FB" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans4), textAlign: 'center' }}><Slice large rotation={135} color="#FDC800" /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap" justifyContent="flex-end">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans7) }}><Slice color="#8E43FF" rotation={45} /></animated.div></Box>
          </SliceRow>
          <SliceRow flexWrap="wrap">
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans5), textAlign: 'center' }}><Slice large rotation={-135} color="#FDC800" /></animated.div></Box>
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans6), textAlign: 'center' }}><Slice rotation={-135} color="#7ADFC9" /></animated.div></Box>
            <Box flex="1" />
            <Box width={3/12}><animated.div style={{ transform: props.xy.interpolate(trans5), textAlign: 'center' }}><Slice large rotation={135} color="#29A0FB" /></animated.div></Box>
          </SliceRow>
        </div>
      </SliceContainer>
  );
};

export default Slices;
