import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/emotion';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import styled from '@emotion/styled';

import Heading from '../components/Heading';
import Text from '../components/Text';
import Button from '../components/Button';

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  img {
    transition: all 0.5s ease-in-out !important;
  }
  img:hover {
    transform: scale(1.02);
  }
`;

const Project = ({
  title,
  category,
  description,
  slug,
  even,
  imageUrl,
  imageAlt,
   ...props }) => {
  return (
      <ProjectLink to={slug}>
        <Flex data-aos="fade-up" flexWrap="wrap" mb={[4, 4, 6]} alignItems="flex-end">
          <Box order={even ? ['1', '1', '2'] : '1'} width={[1, 1, 8/10]}>
            <Img alt={imageAlt} fluid={imageUrl.childImageSharp.fluid} />
          </Box>
          <Box order={even ? ['2', '2', '1'] : '2'} mt={['0', '0', '6.25%']} style={{ position: 'relative', zIndex: '2' }} ml={even ? '0' : ['0', '0', '-20%']} mr={even ? ['0', '-20%'] : '0'} width={[1, 1, 4/10]}>
            <Flex p={'10%'} bg="white" flexWrap="wrap">
              <Box>
                <Text fontSize={['14px', '14px', 0]} pb={1}>{category}</Text>
                <Heading color="darkGray" fontSize={[3, 3, 4]} mt={0} mb={3}>{title}</Heading>
                <Text mb={[4, 4, 6]} fontSize={[0, 1]}>{description}</Text>
                <Button to={slug}>Learn More</Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </ProjectLink>
  );
};

Project.defaultProps = {
  even: false,
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  even: PropTypes.bool,
  imageUrl: PropTypes.array.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

export default Project;
