import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@rebass/emotion';
import styled from '@emotion/styled';

const AllCaps = styled(Text)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2em;
  position: relative;
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    font-size: 14px;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    top: 140px;
    text-align: right;
    width: 138px;
  }
`;

const TextCaps = ({ children, ...props }) => {
  return (
    <AllCaps
      {...props}
    >
      {children}
    </AllCaps>
  );
};

TextCaps.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextCaps;
