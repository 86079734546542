import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Project from './Project';

const ProjectCards = () => (
  <StaticQuery
    query={graphql`
      query{
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "project-post" } } }
          ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                cardImage {
                  childImageSharp {
                    fluid(maxWidth: 1240) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                shortTitle
                description
                shortDescription
              }
            }
          }
        }
      }`
    }
    render={data =>
      data.allMarkdownRemark.edges.map(({ node: project }, i) => (
        <Project
            title={project.frontmatter.shortTitle}
            description={project.frontmatter.description}
            category="Product & Technology"
            slug={project.fields.slug}
            key={i}
            imageUrl={project.frontmatter.cardImage}
            imageAlt={project.frontmatter.shortDescription}
            even={i%2 ? true : false}
          />
        ))
    } />
);

export default ProjectCards;
